import { ButtonV2 } from '@components/new-components/button-v2';
import { useState } from 'react';

export default function ProdutoIndisponível({ selectedPage, title }) {
  const [createdWarning, setCreatedWarning] = useState({});

  return (
    <>
      <div className="flex flex-col rounded-b-lg border border-t-0 border-solid border-neutral-300 bg-white p-5">
        <div className="border-0 border-b border-solid border-[#EDEDED]">
          <p className="text-sm font-semibold text-neutral-800">
            Este produto ainda não está disponível. Aguarde, em breve teremos
            novidades!
          </p>
        </div>
        {!createdWarning[selectedPage] && (
          <ButtonV2
            ghost
            className="mt-4"
            onClick={() =>
              setCreatedWarning({ ...createdWarning, [selectedPage]: true })
            }
          >
            <ButtonV2.Text>Avisar quando estiver disponível</ButtonV2.Text>
          </ButtonV2>
        )}
        {createdWarning[selectedPage] && (
          <p className="m-0 mt-4 text-sm text-price">
            Aviso criado. Quando o produto{' '}
            <span className="font-semibold">{title}</span> estiver disponível,
            você será avisado pelo e-mail{' '}
            <span className="font-semibold">teste@book2b.com.br</span>.
          </p>
        )}
      </div>
    </>
  );
}
