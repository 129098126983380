export function formatCurrency(value = null, showNumeric = false) {
  let newValue = value ?? 0;
  if (newValue === 0 && !showNumeric) return '-';
  return Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(newValue);
}

export function normalizeCurrencyInput(value) {
  return value
    .replace('/[R$s]/g', '')
    .replace('.', '')
    .replace(',', '.')
    .trim();
}
