import { useState, useEffect } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import { MdOutlineModeEdit } from 'react-icons/md';
import { PiCaretRightBold } from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';
import api from '@services/api';
import Globals from '../components/Globals';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

export default function AereoFrotaCadastrar() {
  const [endpoint] = useState(Globals.endPoint);
  const navigate = useNavigate();
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert, setTxtAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fabricante, setFabricante] = useState('');
  const [fabricanteData, setFabricanteData] = useState([]);
  const [companhia, seCompanhia] = useState('');
  const [companhiaData, seCompanhiaData] = useState([]);
  const [fabricanteModelo, setFabricanteModelo] = useState('');

  useEffect(() => {
    setLoading(true);
    var query = 'SELECT * FROM `vpcharter_companhia` ';
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          seCompanhiaData(response.data);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Registro não encontrado!');
          setTimeout(() => setTypeAlert(''), 3000);
        }
      })
      .catch((error) => alert(error));

    var query = 'SELECT * FROM `vpcharter_fabricante` order by `aeronave` ';
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setFabricanteData(response.data);
          setLoading(false);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Registro não encontrado!');
          setTimeout(() => setTypeAlert(''), 3000);
        }
      })
      .catch((error) => alert(error));
  }, []);

  function validaForm(e) {
    setLoading(true);
    e.preventDefault();

    setTimeout(function () {
      let query =
        "INSERT INTO `vpcharter_frota` (`id`, `fabricante`, `companhia` ) VALUES (NULL, '" +
        fabricante +
        "', '" +
        companhia +
        "' );";
      let fData = new FormData();
      fData.append('query', query);
      api
        .post(endpoint + '/query', fData)
        .then((response) => {
          if (response.status === 201) {
            setTypeAlert('alert-success');
            setTxtAlert('Cadastro com sucesso');

            let query =
              "SELECT * FROM `vpcharter_frota` WHERE `fabricante` = '" +
              fabricante +
              "' AND `companhia` = '" +
              companhia +
              "'  ";
            let fData = new FormData();
            fData.append('query', query);
            api
              .post(endpoint + '/query', fData)
              .then((response) => {
                if (response.data.length > 0) {
                  setFabricanteData(response.data);
                  let id = response.data[0]['id'];
                  setTimeout(
                    () =>
                      navigate(PagePath.AereoFrotaAssentos.replace(':id', id)),
                    1000,
                  );
                  setLoading(false);
                } else {
                  setTypeAlert('alert-danger');
                  settitleAlert('Registro não encontrado!');
                  setTimeout(() => setTypeAlert(''), 3000);
                }
              })
              .catch((error) => alert(error));
          } else {
            setTypeAlert('alert-danger');
            settitleAlert('Erro na Gravação!');
            setTimeout(() => setTypeAlert(''), 3000);
          }
        })
        .catch((error) => alert(error));

      setLoading(false);
    }, 1000);
  }

  function changeFabricante(e) {
    if (e == 'new') {
      setOpenModal(true);
    } else {
      setFabricante(e);
    }
  }

  function editFabricante(id, fabricante) {
    for (let i = 0; i < fabricanteData.length; i = i + 1) {
      if (fabricanteData[i].id != id) {
        document.getElementById(
          'inputFabricante' + fabricanteData[i].id,
        ).style.display = 'none';
        document.getElementById(
          'nomeFabricante' + fabricanteData[i].id,
        ).style.display = 'block';
        document.getElementById(
          'btnEditFabricante' + fabricanteData[i].id,
        ).style.opacity = '0';
      }
    }
    document.getElementById('btnEditFabricante' + id).style.opacity = '1';
    document.getElementById('nomeFabricante' + id).style.display = 'none';
    const elem = document.getElementById('inputFabricante' + id);
    elem.style.display = 'block';
    if (elem.value == '') {
      elem.value = fabricante;
    }
    elem.focus();
  }

  return (
    <>
      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div className="globalModal">
          <GrClose className="closeModal" onClick={() => setOpenModal(false)} />
          <h5>Cadastro Fabricante/Modelo</h5>
          <form
            onSubmit={(e) => validaFormFabricante(e)}
            onFocus={() => limpaFabricante()}
          >
            <div className="row mb-3 mt-3">
              <div className="col-9 mr-0 pr-0">
                <input
                  type="text"
                  required
                  placeholder="Fabricante/Modelo"
                  value={fabricanteModelo}
                  onChange={(e) => setFabricanteModelo(e.target.value)}
                ></input>
              </div>
              <div className="col  ml-0 pl-0">
                <button type="submit">
                  <span>
                    <BsCheckLg />
                  </span>
                </button>
              </div>
            </div>
          </form>

          <div className="listModal">
            <table className="table-hover table">
              <tbody>
                {fabricanteData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.id}</td>
                    <td
                      onMouseOver={() => editFabricante(data.id, data.aeronave)}
                    >
                      <input
                        type="text"
                        required
                        id={'inputFabricante' + data.id}
                        style={{ display: 'none' }}
                      ></input>
                      <span id={'nomeFabricante' + data.id}>
                        {data.aeronave}
                      </span>
                    </td>
                    <td>
                      <MdOutlineModeEdit
                        className="icoEdit"
                        id={'btnEditFabricante' + data.id}
                        onClick={() => updateFabricante(data.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Frotas',
            link: PagePath.AereoFrota,
          },
          {
            label: 'Cadastro Aeronave',
            link: PagePath.AereoFrotaCadastrar,
          },
        ]}
        title="Cadastro Aeronave"
      />

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={(e) => validaForm(e)}>
              <div className="row">
                <div className="col-4">
                  <label>Fabricante/Modelo</label>
                  <select
                    name="select"
                    value={fabricante}
                    onChange={(e) => changeFabricante(e.target.value)}
                    required
                  >
                    {/*
                          <option disabled></option>
                          <option value="new">❯ Cadastrar/Editar</option>
                          <option disabled>━━━━━━━━━━━━━━</option>
                          */}
                    <option value=""> </option>
                    {fabricanteData.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.aeronave}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-5">
                  <label>Companhia Aérea</label>
                  <select
                    name="select"
                    value={companhia}
                    onChange={(e) => seCompanhia(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    {companhiaData.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3">
                  <button type="submit">
                    <span className={loading ? 'hide' : ''}>
                      <PiCaretRightBold /> Avançar
                    </span>
                    <span className={loading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
