import { MdOutlineLocalOffer } from 'react-icons/md';
import { PiAirplaneTilt, PiCallBellLight } from 'react-icons/pi';
import { useAccessControl } from '@system/acl';
import { Menu } from '../components/menu/menu.jsx';
import { PagePath, pagesACL } from '../pages-config';
import PageHeader from '@components/page-header/index.jsx';

export default function Aereo() {
  const { hasPermission } = useAccessControl();

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Aéreo', link: PagePath.Aereo },
        ]}
      />
      <Menu submenu className={'m-0 pl-0 pt-4'}>
        {hasPermission(pagesACL[PagePath.AereoFrota]) && (
          <Menu.Item
            to={PagePath.AereoFrota}
            title={'Frota'}
            icon={<PiAirplaneTilt className={'self-start'} />}
          />
        )}
        {hasPermission(pagesACL[PagePath.AereoSaidas]) && (
          <Menu.Item
            to={PagePath.AereoSaidas}
            title={'Saídas'}
            icon={<MdOutlineLocalOffer className={'self-start'} />}
          />
        )}
        <Menu.Item
          to={PagePath.AereoReservas}
          title={'Reservas'}
          icon={<PiCallBellLight className={'self-start'} />}
        />
      </Menu>
    </>
  );
}
