import { forwardRef, useState, useId, useRef, useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';

// eslint-disable-next-line react/display-name
export const SearchInputV2 = forwardRef(
  (
    { className, handleSearch, height = 'h-12', error, setError, ...props },
    ref,
  ) => {
    const id = useId();

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') handleSearch();
      else setError('');
    };

    return (
      <>
        <div className="flex w-full">
          <div className="relative w-full">
            <div
              className={`flex ${height} w-full items-center justify-between rounded-md border !border-solid border-neutral-600 px-1 hover:border-2 hover:border-neutral-800 focus:border-neutral-800`}
            >
              <input
                id={id}
                ref={ref}
                className={`h-full w-full border-none bg-transparent px-4 py-[14px] text-sm font-normal outline-none placeholder:italic ${className}`}
                {...props}
                onKeyDown={handleKeyDown}
              />
              <BiSearch
                size={20}
                className="m-0 h-max w-max cursor-pointer rounded-md p-2 text-neutral-800 hover:bg-neutral-100"
                onClick={() => handleSearch()}
              />
            </div>
            {error && (
              <>
                <div className="absolute z-50 hidden rounded-md bg-white p-5 text-center drop-shadow-md md:flex">
                  <label className="m-0 text-sm font-semibold">{error}</label>
                </div>
                <div className="mt-2.5 flex md:hidden">
                  <label className="m-0 text-sm font-semibold">{error}</label>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  },
);
