import { PiAirplaneTiltLight } from 'react-icons/pi';
import { BiBed } from 'react-icons/bi';
import { BsSuitcaseLg } from 'react-icons/bs';
import { LiaCarSideSolid } from 'react-icons/lia';
import { RiBus2Line } from 'react-icons/ri';
import BasicMenu from '@components/basic-menu';
import { useState } from 'react';
import ProdutoIndisponível from './ProdutoIndisponível';
import AereoReservationForm from '@components/modules/aereo/ReservationForm';
import { useEffect } from 'react';

const pages = {
  airlineTickets: {
    title: 'Passagens Aéreas',
    mobileTitle: 'Passagens',
    icon: PiAirplaneTiltLight,
  },
  hostings: {
    title: 'Hospedagem',
    icon: BiBed,
  },
  packages: {
    title: 'Pacotes',
    icon: BsSuitcaseLg,
  },
  services: {
    title: 'Serviços',
    icon: LiaCarSideSolid,
  },
  roadTransports: {
    title: 'Rodoviário',
    icon: RiBus2Line,
  },
};

export default function MenuTipoReservas() {
  const [selectedPage, setSelectedPage] = useState('airlineTickets');
  const [selectedComponent, setSelectedComponent] = useState(
    <AereoReservationForm />,
  );

  useEffect(() => {
    if (selectedPage == 'airlineTickets')
      setSelectedComponent(<AereoReservationForm />);
    else
      setSelectedComponent(
        <ProdutoIndisponível
          selectedPage={selectedPage}
          title={pages[selectedPage].title}
        />,
      );
  }, [selectedPage]);

  return (
    <>
      <div>
        <BasicMenu
          pages={pages}
          horizontal
          selectedPage={selectedPage}
          handleClick={(e) => setSelectedPage(e)}
        />
        {selectedComponent}
      </div>
    </>
  );
}
