import { produce } from 'immer';
import { useBookings } from '@stores/bookings';
import { convertTravelerType } from '@system/converters/convertTravelerType';
import { PassengerType } from '@system/enums';
import { formatCurrency } from '@system/utils';

const ReservationTravelersList = () => {
  const { travelers } = useBookings();

  const editTraveler = (info) => {
    useBookings.setState(
      produce((draft) => {
        draft.traveler = info;
        draft.modal.createTravelerVisible = true;
        if (info?.type?.toLowerCase() === 'inf') {
          draft.passengerType = PassengerType.PassengerBaby;
          draft.seat = null;
        } else {
          const [column, row] = info.seat;
          draft.passengerType = PassengerType.PassengerCommon;
          draft.seat = { column, row, category: info.category };
        }
      }),
    );
  };

  return (
    <ul className="m-0 flex list-none flex-col gap-y-3 p-0">
      {(travelers ?? []).map((traveler) => (
        <li
          key={traveler?.id}
          onClick={editTraveler.bind(this, traveler)}
          className="flex cursor-pointer flex-row items-center justify-between gap-4 rounded-md border border-solid border-neutral-300 p-3 shadow-[0px_4px_8px_0px_rgba(84,89,98,0.1)]"
        >
          <div className="flex h-[35px] w-[35px] flex-col items-center justify-center  self-start rounded-md bg-primary p-[10px] text-center text-white">
            {traveler?.seat ?? '-'}
          </div>
          <div className="flex w-full flex-col">
            <span className="text-small font-bold text-primary ">
              {convertTravelerType(traveler?.type)}
            </span>
            <span className="text-small font-normal">
              {[traveler?.name, traveler?.lastName].join(' ')}
            </span>
          </div>
          <div className="whitespace-nowrap text-right text-body font-bold text-price">
            {formatCurrency(traveler?.value ?? 0, true)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ReservationTravelersList;
