import ReservationsForm from '@components/modules/aereo/ReservationForm';
import { useAuthentication } from '@stores/authentication';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

export default function AereoReservas() {
  const { session } = useAuthentication();
  const pageTitle = session?.agency?.nomeFantasia ?? 'Buscar reserva';

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Reservas', link: PagePath.AereoReservas },
        ]}
        title={pageTitle}
      />
      <ReservationsForm />
    </>
  );
}
