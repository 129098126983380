import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import * as yup from 'yup';
import { InputDatePicker } from '@components/input-custom';
import LoadingAnimation from '@components/LoadingAnimation';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import { convertBookingStatus } from '@system/converters/convertBookingStatus';
import { convertBookingStatusColor } from '@system/converters/convertBookingStatusColor';
import { convertBookingType } from '@system/converters/convertBookingType';
import { BookingStatus } from '@system/enums';
import { formatCurrency } from '@system/utils';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

const ORDER_BY_OPTIONS = [
  { value: 'DESC', label: 'Últimas reservas feitas' },
  { value: 'ASC', label: 'Primeiras reservas feitas' },
];
const GestaoReservas = () => {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [filter, setFilter] = useState({
    dateStart: '',
    dateEnd: '',
    bookingCode: '',
    status: '',
    agencyName: '',
  });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [orderby, setOrderby] = useState(ORDER_BY_OPTIONS[0].value);
  const { session } = useAuthentication();
  const navigate = useNavigate();
  const statusOptions = Object.keys(BookingStatus).map((key) => ({
    value: BookingStatus[key],
    label: convertBookingStatus(BookingStatus[key]),
  }));

  const startDateInputRef = useRef(null);
  const returnDateInputRef = useRef(null);

  const searchParams = new URLSearchParams(location.search);

  const paramsObject = {};
  for (let [key, value] of searchParams.entries()) {
    paramsObject[key] = value;
  }

  const [formDate, setformData] = useState({
    startDate: paramsObject.startDate ?? null,
    months: paramsObject?.months?.split(',') ?? [],
  });

  function makeDateInputValue(value) {
    const dateFormat = value ? format(value, 'dd/MM/yyyy') : '';

    return dateFormat;
  }

  const filterSchema = yup.object().shape({
    dateStart: yup
      .string()
      .test(
        'dateStart-required-if-dateEnd',
        'Informe a data inicial',
        function (value) {
          const { dateEnd } = this.parent;
          return !dateEnd || (dateEnd && value);
        },
      )
      .label('Data inicial'),
    dateEnd: yup
      .string()
      .test(
        'dateEnd-required-if-dateStart',
        'Informe a data fim',
        function (value) {
          const { dateStart } = this.parent;
          return !dateStart || (dateStart && value);
        },
      )
      .label('Data fim'),
    bookingCode: yup.string().label('Código da reserva'),
    status: yup.string().label('Status'),
    agencyName: yup.string().label('Nome da agência'),
  });
  const form = useForm({
    resolver: yupResolver(filterSchema),
  });

  const getBookings = async () => {
    setLoading(true);
    if (page === 1) setBookings([]);
    try {
      const params = {
        limit: 10,
        page,
        order: orderby,
        codBooking: filter?.bookingCode || '',
        status: filter?.status || '',
        agencyName: filter?.agencyName || '',
      };

      if (filter?.period) {
        params.dateStart = format(filter.period[0], 'yyyy-MM-dd');
        params.dateEnd = format(filter.period[1], 'yyyy-MM-dd');
      }

      const { data } = await api.get('/bookings', {
        params,
      });
      setTotalPage(data.totalPages);
      setBookings((prev) =>
        page === 1 ? data.result : [...prev, ...data.result],
      );
    } catch (err) {
      setBookings([]);
      setLoading(false);
    }
    setLoading(false);
  };
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const clearFilters = () => {
    setFilter({
      period: '',
      bookingCode: '',
      status: '',
      agencyName: '',
    });
    form.reset({
      dateStart: '',
      dateEnd: '',
      bookingCode: '',
      status: '',
      agencyName: '',
    });
  };
  const getDateMinus12Months = () => {
    const currentDate = new Date(); // Obtém a data atual
    return new Date(currentDate.setMonth(currentDate.getMonth() - 12)); // Subtrai 12 meses
  };

  function handleDepartureDateChange(date) {
    const newReturnDate = date > formDate.dateStart ? null : formDate.dateStart;
    /**
     * @note This will ensure that returnDateInputRef is focused correctly
     */
    setformData((prev) => ({
      ...prev,
      dateEnd: newReturnDate,
      dateStart: date,
    }));

    returnDateInputRef.current?.focus();
    returnDateInputRef.current?.click();
  }

  function handleFieldChange(field, value) {
    setformData((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  const onFilter = (formData) => {
    if (!formDate.dateStart && !formDate.dateEnd) {
      if (Object.keys(formData).every((i) => formData[i].length === 0)) {
        return;
      }
    }
    setFilter({
      period:
        formDate.dateStart && formDate.dateEnd
          ? [formDate.dateStart, formDate.dateEnd]
          : '',
      bookingCode: formData.bookingCode,
      status: formData.status,
      agencyName: formData.agencyName,
    });
    setPage(1);
  };
  useEffect(() => {
    getBookings();
  }, []);
  useEffect(() => {
    getBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderby, page, filter]);

  const isFiltering = !!Object.keys(filter)?.filter(
    (i) => filter[i]?.length > 0,
  )?.length;

  return (
    <>
      {loading && <LoadingAnimation />}
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Reservas', link: PagePath.Reservas },
          {
            label: 'Gestão de reservas',
            link: PagePath.GestaoReservas,
          },
        ]}
        title="Gestão de reservas"
      />
      <div className="flex flex-col">
        <Container className="border-0">
          <Form
            onSubmit={form.handleSubmit(onFilter)}
            className="flex min-w-full flex-col gap-4"
          >
            <div className="flex min-w-full flex-row gap-2.5">
              <Form.Group className="basis-1/2">
                <Form.Label>Período data de criação da reserva</Form.Label>
                <div className="border-box flex flex-row gap-2">
                  <InputDatePicker
                    name="startDate"
                    autoComplete="off"
                    placeholder="De"
                    icon={BiCalendar}
                    value={makeDateInputValue(formDate.dateStart)}
                    noValidate
                    disableResume={true}
                    startCalendar={getDateMinus12Months()}
                    startDay={getDateMinus12Months()}
                    monthStart={12}
                    intervalStart={formDate.dateStart}
                    changeOnly="start"
                    multiCalendar={false}
                    selectedMonths={formDate.months}
                    onDateChange={handleDepartureDateChange}
                    onMonthsChange={(months) =>
                      handleFieldChange('months', months)
                    }
                    ref={startDateInputRef}
                  />
                  <InputDatePicker
                    name="returnDate"
                    autoComplete="off"
                    placeholder="Até"
                    icon={BiCalendar}
                    value={makeDateInputValue(formDate.dateEnd)}
                    noValidate
                    disableResume={true}
                    intervalStart={formDate.dateStart}
                    intervalEnd={formDate.dateEnd}
                    changeOnly="end"
                    multiCalendar={false}
                    startCalendar={formDate.dateStart}
                    startDay={formDate.dateStart}
                    selectedMonths={formDate.months}
                    onDateChange={(date) => handleFieldChange('dateEnd', date)}
                    ref={returnDateInputRef}
                  />
                </div>
                <Form.Message error={form.formState.errors.dateStart} />
                <Form.Message error={form.formState.errors.dateEnd} />
              </Form.Group>
              <Form.Group className="basis-1/4">
                <Form.Label>Nro da reserva</Form.Label>
                <InputV2
                  {...form.register('bookingCode')}
                  placeholder="Digite o número da reserva"
                />
              </Form.Group>
              <Form.Group className="basis-1/4">
                <Form.Label>Status</Form.Label>
                <SelectV2
                  options={statusOptions}
                  {...form.register('status')}
                  value={form.watch('status')}
                  placeholder="Selecione"
                />
              </Form.Group>
              {session.role < Role.Gestao && (
                <Form.Group className="flex-col items-center justify-end">
                  <Form.Label>&nbsp;</Form.Label>
                  <ButtonV2
                    iconOnly
                    ghost
                    className="!m-0 box-border size-9 !p-0"
                  >
                    <BiSearch />
                  </ButtonV2>
                </Form.Group>
              )}
            </div>
            {session.role >= Role.Gestao && (
              <div className="flex flex-row gap-2.5">
                <Form.Group className="basis-full">
                  <Form.Label>Nome da agência</Form.Label>
                  <InputV2
                    {...form.register('agencyName')}
                    placeholder="Digite o nome da agência"
                  />
                </Form.Group>

                <Form.Group className="flex-col items-center justify-end">
                  <ButtonV2
                    iconOnly
                    ghost
                    className="m-0 !mb-1 box-border size-9 !p-0"
                  >
                    <BiSearch />
                  </ButtonV2>
                </Form.Group>
              </div>
            )}
          </Form>
        </Container>
        <div className="my-5 flex w-full flex-row items-end justify-end">
          <Form.Group className="w-[300px]">
            <Form.Label>Ordenação</Form.Label>
            <SelectV2
              options={ORDER_BY_OPTIONS}
              value={orderby}
              onChange={(e) => {
                setOrderby(e.target.value);
                setPage(1);
              }}
              placeholder="Ordenar por"
            />
          </Form.Group>
        </div>
        {isFiltering && (
          <div className="flex w-full flex-row items-center justify-between border-0 border-b border-t  border-solid border-neutral-400 py-2.5">
            <div>
              {Object.keys(filter)
                .filter((i) => filter[i]?.length > 0)
                ?.map((i) => {
                  let filterValue = '';
                  if (i === 'period')
                    filterValue = filter[i]
                      .map((d) => format(d, 'dd/MM/yyyy'))
                      .join(' - ');
                  else if (i === 'status')
                    filterValue = convertBookingStatus(filter[i]);
                  else filterValue = filter[i];
                  return (
                    <span
                      onClick={() => {
                        setFilter((prev) => ({
                          ...prev,
                          [i]: '',
                        }));
                        if (i === 'period') {
                          form.resetField('dateStart');
                          form.resetField('dateEnd');
                          setformData({
                            startDate: null,
                            months: [],
                          });
                        } else {
                          form.resetField(i);
                        }
                      }}
                      key={i}
                      role="button"
                      className="!m mr-4 inline-block rounded-2xl border border-solid border-secondary bg-secondary-100 px-4 py-1.5 text-body font-semibold text-secondary"
                    >
                      {filterValue} <AiOutlineClose className="ml-2.5" />
                    </span>
                  );
                })}
            </div>
            <ButtonV2 link onClick={clearFilters}>
              <ButtonV2.Text>Apagar todos</ButtonV2.Text>
            </ButtonV2>
          </div>
        )}
        <Container className="border-box border-none bg-white p-5">
          <h5 className="!mx-0 mb-5 px-0 text-heading-2 text-primary">
            Listagem de reservas
          </h5>
          <div className="border-box m-0 w-full rounded-md border border-solid border-neutral-300 bg-white px-4 pt-4">
            <div className="w-full">
              <div className="col-12 table_list">
                <table className="tblDefault remove-last-border p2 overflow-x-auto">
                  <thead>
                    <tr>
                      <th scope="col w-[100px]">Data de criação</th>
                      <th scope="col w-[100px]">Nro da reserva</th>
                      <th scope="col">Status</th>
                      <th
                        scope="col"
                        className={
                          [Role.Financeiro, Role.Administrador].includes(
                            session.role,
                          )
                            ? ''
                            : 'hide'
                        }
                      >
                        Nome da Agência
                      </th>
                      <th scope="col">Serviço</th>
                      <th scope="col">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings?.map((booking) => (
                      <tr
                        key={booking.id}
                        className={'hover:!bg-secondary-100'}
                        onClick={() => {
                          navigate(
                            PagePath.DetalhesReserva.replace(':id', booking.id),
                          );
                        }}
                      >
                        <td className="w-1/12">
                          {format(booking.createdAt, 'dd/MM/yyyy')}
                        </td>
                        <td className="w-2/12">{booking.code}</td>
                        <td className="w-2/12">
                          <div
                            className={`statusPgto font-medium ${convertBookingStatusColor(booking.status)}`}
                          >
                            {convertBookingStatus(booking.status)}
                          </div>
                        </td>
                        {[Role.Financeiro, Role.Administrador].includes(
                          session.role,
                        ) && (
                          <td className="w-4/12">
                            {booking?.agency?.tradeName}
                          </td>
                        )}

                        <td className="w-2/12">
                          {convertBookingType(booking.type)}
                        </td>
                        <td className="w-2/12 text-body font-semibold text-price">
                          {formatCurrency(booking.value, true)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {totalPage > 0 && totalPage != page && (
            <div className="mt-7 flex w-full justify-center">
              <ButtonV2 link loading={loading} onClick={loadMore}>
                <ButtonV2.Text>Ver mais resultados</ButtonV2.Text>
              </ButtonV2>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};
export default GestaoReservas;
