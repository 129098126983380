import { useState, useEffect } from 'react';
import { BiSolidPencil } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { PiWarningFill } from 'react-icons/pi';
import { TbClipboardList } from 'react-icons/tb';
import { VscEdit } from 'react-icons/vsc';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@components/button';
import PageHeader from '@components/page-header';
import RouteCardDetailed from '@components/route-card/RouteCardDetailed';
import api from '@services/api';
import {
  formatCurrency,
  getFormattedDateTime,
  normalizeCurrencyInput,
} from '@system/utils';
import { calculateSalesPrice } from '@system/utils/calculateSalesPrice';
import IntlCurrencyInput from '../components/intl-currency-input';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AereoSaidasPrecificar() {
  const { id } = useParams();
  const [alert, setAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert, setTxtAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataBase, setFretamentoData] = useState([]);
  const [mustBeConfirm, setMustBeConfirm] = useState(false);

  const [primeiraClasse, setPrimeiraClasse] = useState([]);
  const [premium, setPremium] = useState([]);
  const [executiva, setExecutiva] = useState([]);
  const [economica, setEconomica] = useState([]);
  const [primeiraclasse_chd, setPrimeiraClasse_chd] = useState([]);
  const [premium_chd, setPremium_chd] = useState([]);
  const [executiva_chd, setExecutiva_chd] = useState([]);
  const [economica_chd, setEconomica_chd] = useState([]);
  const [primeiraclasse_inf, setPrimeiraClasse_inf] = useState([]);
  const [premium_inf, setPremium_inf] = useState([]);
  const [executiva_inf, setExecutiva_inf] = useState([]);
  const [economica_inf, setEconomica_inf] = useState([]);
  const [custo_primeiraClasse, setCusto_PrimeiraClasse] = useState([]);
  const [custo_premium, setCusto_Premium] = useState([]);
  const [custo_executiva, setCusto_Executiva] = useState([]);
  const [custo_economica, setCusto_Economica] = useState([]);
  const [custo_primeiraclasse_chd, setCusto_Primeiraclasse_chd] = useState([]);
  const [custo_premium_chd, setCusto_Premium_chd] = useState([]);
  const [custo_executiva_chd, setCusto_Executiva_chd] = useState([]);
  const [custo_economica_chd, setCusto_Economica_chd] = useState([]);
  const [custo_primeiraclasse_inf, setCusto_Primeiraclasse_inf] = useState([]);
  const [custo_premium_inf, setCusto_Premium_inf] = useState([]);
  const [custo_executiva_inf, setCusto_Executiva_inf] = useState([]);
  const [custo_economica_inf, setCusto_Economica_inf] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [changeClasse, setChangeClasse] = useState('');
  const [changeTipo, setChangeTipo] = useState('');
  const [changeCateg, setChangeCateg] = useState('');
  const [changeValor, setChangeValor] = useState(0);
  const [alertValor, setAlertValor] = useState(false);
  const [dataBaseAeroportos, setDataBaseAeroportos] = useState([]);

  const [showTarifas, setShowTarifas] = useState(false);
  const [dataBaseRegrasTarifarias, setDataBaseRegrasTarifarias] = useState([]);
  const [baseRegrasTarifarias, setBaseRegrasTarifarias] = useState('');
  const [taxaEmbarque, setTaxaEmbarque] = useState(0);
  const [markup, setMarkup] = useState('');
  const [taxaEmbarqueOld, setTaxaEmbarqueOld] = useState('');
  const [markupOld, setMarkupOld] = useState('');

  const [showList, setShowList] = useState('');
  const [nomenPC, setNomenPC] = useState('Classe 1');
  const [nomenPR, setNomenPR] = useState('Classe 2');
  const [nomenEX, setNomenEX] = useState('Classe 3');
  const [nomenEC, setNomenEC] = useState('Classe 4');

  const [totalFilC1, setTotalFilC1] = useState(0);
  const [totalFilC2, setTotalFilC2] = useState(0);
  const [totalFilC3, setTotalFilC3] = useState(0);
  const [totalFilC4, setTotalFilC4] = useState(0);

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };
  useEffect(() => {
    carregaPagina();
  }, []);

  function carregaPagina() {
    setLoading(true);
    fetchCharterDetails();
    fetchTariffRules();
    fetchAirports();
    carregaTarifas();
  }

  async function fetchCharterDetails() {
    try {
      const charterDetails = await api.get(`charters/${id}/item/details`);
      if (charterDetails.data) {
        const taxaEmbarque = charterDetails.data['taxa_embarque'];
        const markup = charterDetails.data['markup'];
        const airline =
          charterDetails.data['outboundRoute']['fleet']['airline'];

        setFretamentoData(charterDetails.data);
        setTaxaEmbarque(taxaEmbarque);
        setTaxaEmbarqueOld(taxaEmbarque);
        setMarkup(markup);
        setMarkupOld(markup);
        setNomenPC(airline['nomen_pc']);
        setNomenPR(airline['nomen_pr']);
        setNomenEX(airline['nomen_ex']);
        setNomenEC(airline['nomen_ec']);

        const seatCounts = await api.get(
          `seat/counts/airplane/${charterDetails.data['outboundRoute']['aeronave']}`,
        );

        if (seatCounts.data) {
          setTotalFilC1(seatCounts.data['totalFilC1']);
          setTotalFilC2(seatCounts.data['totalFilC2']);
          setTotalFilC3(seatCounts.data['totalFilC3']);
          setTotalFilC4(seatCounts.data['totalFilC4']);
        }
      } else {
        setTypeAlert('alert-danger');
        settitleAlert('Saída não encontrada!');
        setTimeout(() => setTypeAlert(''), 3000);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  async function fetchTariffRules() {
    try {
      const tariffRules = await api.get('tariff-rules');
      if (tariffRules.data) {
        setDataBaseRegrasTarifarias(tariffRules.data);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  async function fetchAirports() {
    try {
      const airports = await api.get('airports');
      if (airports.data) {
        setDataBaseAeroportos(airports.data);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  function carregaTarifas() {
    const classes = ['PC', 'PR', 'EX', 'EC'];
    const tipos = ['CO', 'VV'];
    const categorias = ['ADT', 'CHD', 'INF'];

    const mapeamentoSetters = {
      PC: {
        CO: {
          ADT: setCusto_PrimeiraClasse,
          CHD: setCusto_Primeiraclasse_chd,
          INF: setCusto_Primeiraclasse_inf,
        },
        VV: {
          ADT: setPrimeiraClasse,
          CHD: setPrimeiraClasse_chd,
          INF: setPrimeiraClasse_inf,
        },
      },
      PR: {
        CO: {
          ADT: setCusto_Premium,
          CHD: setCusto_Premium_chd,
          INF: setCusto_Premium_inf,
        },
        VV: {
          ADT: setPremium,
          CHD: setPremium_chd,
          INF: setPremium_inf,
        },
      },
      EX: {
        CO: {
          ADT: setCusto_Executiva,
          CHD: setCusto_Executiva_chd,
          INF: setCusto_Executiva_inf,
        },
        VV: {
          ADT: setExecutiva,
          CHD: setExecutiva_chd,
          INF: setExecutiva_inf,
        },
      },
      EC: {
        CO: {
          ADT: setCusto_Economica,
          CHD: setCusto_Economica_chd,
          INF: setCusto_Economica_inf,
        },
        VV: {
          ADT: setEconomica,
          CHD: setEconomica_chd,
          INF: setEconomica_inf,
        },
      },
    };

    const carregarDados = async (classe, tipo, categoria) => {
      try {
        const response = await api.post('fare/show', {
          id,
          classe,
          tipo,
          categoria,
        });

        if (response.data.length > 0) {
          mapeamentoSetters[classe][tipo][categoria](response.data);
          setShowTarifas(true);
        }
      } catch (error) {
        console.error(
          `Erro ao carregar tarifas para ${classe}-${tipo}-${categoria}:`,
          error,
        );
        alert(
          'Ocorreu um erro ao carregar as tarifas. Tente novamente mais tarde.',
        );
      }
    };

    const carregarTodasTarifas = async () => {
      for (const classe of classes) {
        for (const tipo of tipos) {
          for (const categoria of categorias) {
            await carregarDados(classe, tipo, categoria);
          }
        }
      }
      setLoading(false);
    };

    carregarTodasTarifas();
  }

  function validaForm(e) {
    e.preventDefault();

    if (changeTipo == 'Custo Operacional') {
      var tipo = 'CO';
    } else {
      var tipo = 'VV';
    }
    if (changeClasse == 'Primeira Classe') {
      var classe = 'PC';
    }
    if (changeClasse == 'Premium') {
      var classe = 'PR';
    }
    if (changeClasse == 'Executiva') {
      var classe = 'EX';
    }
    if (changeClasse == 'Econômica') {
      var classe = 'EC';
    }
    let vl1 = document
      .getElementById('changeValor')
      .value.replace('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace(' ', '.')
      .trim();

    const [{ valor: adtVlr } = {}] = custo_primeiraClasse ?? [{}];
    const [{ valor: chdVlr } = {}] = custo_primeiraclasse_chd ?? [{}];
    const [{ valor: infVlr } = {}] = custo_primeiraclasse_inf ?? [{}];
    const valorOperacional = {
      ['ADT']: adtVlr ?? 0,
      ['CHD']: chdVlr ?? 0,
      ['INF']: infVlr ?? 0,
    };
    if (!mustBeConfirm && Number(vl1) < Number(valorOperacional[changeCateg])) {
      setMustBeConfirm(true);
      return;
    }
    if (Number(changeValor) === Number(vl1)) {
      var newValor = false;
    } else {
      var newValor = true;
    }
    let hoje = new Date();
    let mes = Number(hoje.getMonth()) + Number(1);
    let formatDateTime =
      hoje.getFullYear() +
      '-' +
      mes +
      '-' +
      hoje.getDate() +
      ' ' +
      hoje.getHours() +
      ':' +
      hoje.getMinutes() +
      ':' +
      hoje.getSeconds();
    let vl2 = vl1;
    setMustBeConfirm(false);
    setLoading(true);

    if (newValor) {
      const token = localStorage.getItem('access_token');
      const payload = {
        id,
        classe,
        tipo,
        categoria: changeCateg,
        valor: vl1,
        dateTime: getFormattedDateTime(),
      };
      api
        .post(
          'fare/insert',
          { payload },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((response) => {
          if (response.status === 201) {
            if (tipo == 'CO') {
              vl2 = calculateSalesPrice(
                Number(vl1),
                Number(markup),
                Number(taxaEmbarque),
              );

              const payload = {
                id,
                classe: 'VV',
                tipo,
                categoria: changeCateg,
                valor: vl2,
                dateTime: getFormattedDateTime(),
              };

              api.post(
                'fare/insert',
                { payload },
                { headers: { Authorization: `Bearer ${token}` } },
              );
            }

            setOpenModal(false);
            setAlert(true);
            setTypeAlert('alert-success');
            setTxtAlert('Cadastrado com sucesso!');
            setTimeout(() => setAlert(false), 4000);
            setChangeValor(0);
            carregaTarifas();
            setLoading(false);
          } else {
            setOpenModal(false);
            setAlert(true);
            setTypeAlert('alert-danger');
            setTxtAlert('Erro na Gravação!');
            setChangeValor(0);
            setTimeout(() => setAlert(false), 4000);
            setLoading(false);
          }
        })
        .catch((error) => alert(error));
    } else {
      setLoading(false);
      setOpenModal(false);
    }
  }

  function alteraValor(classe, tipo, categ, valor) {
    setAlertValor(false);
    setOpenModal(true);
    setChangeClasse(classe);
    setChangeTipo(tipo);
    setChangeCateg(categ);
    setChangeValor(valor);
  }

  function showListValor(x) {
    if (x == 'taxaembarque') {
      setTaxaEmbarque(taxaEmbarque);
    }
    if (x == 'markup') {
      setMarkup(markup);
    }
    setOpenModal2(true);
    setShowList(x);
  }

  function formatDateTime(x) {
    if (x) {
      let data = new Date(x),
        dia = data.getDate().toString(),
        mes = (data.getMonth() + 1).toString(),
        ano = data.getFullYear().toString().slice(-2),
        hora = data.getHours(),
        minutos = data.getMinutes();

      if (dia < 10) {
        dia = '0' + dia;
      }
      if (mes < 10) {
        mes = '0' + mes;
      }
      if (ano < 10) {
        ano = '0' + ano;
      }
      //if (hora<10) { hora = '0'+hora;}
      //if (minutos<10) { minutos = '0'+minutos;}

      const dataFormatada =
        dia +
        '/' +
        mes +
        '/' +
        ano +
        ' ' +
        hora +
        ':' +
        ('0000' + minutos).slice(-2) +
        'h';
      return dataFormatada;
    } else {
      return '-';
    }
  }

  async function carregaBaseTarifaria() {
    const token = localStorage.getItem('access_token');
    setOpenModal2(false);
    setLoading(true);

    try {
      const baseResponse = await api.get(
        `tariff-rules/${baseRegrasTarifarias}`,
      );

      const baseData = baseResponse.data;

      // Atualiza taxas no estado
      setTaxaEmbarque(baseData.taxa_embarque);
      setMarkup(baseData.markup);

      // insere todas as taxas na tabela tarifas
      const tarifas = [
        {
          classe: 'PC',
          tipo: 'CO',
          categoria: 'ADT',
          valor: baseData.copc_adt,
        },
        {
          classe: 'PC',
          tipo: 'CO',
          categoria: 'CHD',
          valor: baseData.copc_chd,
        },
        {
          classe: 'PC',
          tipo: 'CO',
          categoria: 'INF',
          valor: baseData.copc_inf,
        },
        {
          classe: 'PR',
          tipo: 'CO',
          categoria: 'ADT',
          valor: baseData.copr_adt,
        },
        {
          classe: 'PR',
          tipo: 'CO',
          categoria: 'CHD',
          valor: baseData.copr_chd,
        },
        {
          classe: 'PR',
          tipo: 'CO',
          categoria: 'INF',
          valor: baseData.copr_inf,
        },
        {
          classe: 'EX',
          tipo: 'CO',
          categoria: 'ADT',
          valor: baseData.coex_adt,
        },
        {
          classe: 'EX',
          tipo: 'CO',
          categoria: 'CHD',
          valor: baseData.coex_chd,
        },
        {
          classe: 'EX',
          tipo: 'CO',
          categoria: 'INF',
          valor: baseData.coex_inf,
        },
        {
          classe: 'EC',
          tipo: 'CO',
          categoria: 'ADT',
          valor: baseData.coec_adt,
        },
        {
          classe: 'EC',
          tipo: 'CO',
          categoria: 'CHD',
          valor: baseData.coec_chd,
        },
        {
          classe: 'EC',
          tipo: 'CO',
          categoria: 'INF',
          valor: baseData.coec_inf,
        },
        {
          classe: 'PC',
          tipo: 'VV',
          categoria: 'ADT',
          valor: baseData.vvpc_adt,
        },
        {
          classe: 'PC',
          tipo: 'VV',
          categoria: 'CHD',
          valor: baseData.vvpc_chd,
        },
        {
          classe: 'PC',
          tipo: 'VV',
          categoria: 'INF',
          valor: baseData.vvpc_inf,
        },
        {
          classe: 'PR',
          tipo: 'VV',
          categoria: 'ADT',
          valor: baseData.vvpr_adt,
        },
        {
          classe: 'PR',
          tipo: 'VV',
          categoria: 'CHD',
          valor: baseData.vvpr_chd,
        },
        {
          classe: 'PR',
          tipo: 'VV',
          categoria: 'INF',
          valor: baseData.vvpr_inf,
        },
        {
          classe: 'EX',
          tipo: 'VV',
          categoria: 'ADT',
          valor: baseData.vvex_adt,
        },
        {
          classe: 'EX',
          tipo: 'VV',
          categoria: 'CHD',
          valor: baseData.vvex_chd,
        },
        {
          classe: 'EX',
          tipo: 'VV',
          categoria: 'INF',
          valor: baseData.vvex_inf,
        },
        {
          classe: 'EC',
          tipo: 'VV',
          categoria: 'ADT',
          valor: baseData.vvec_adt,
        },
        {
          classe: 'EC',
          tipo: 'VV',
          categoria: 'CHD',
          valor: baseData.vvec_chd,
        },
        {
          classe: 'EC',
          tipo: 'VV',
          categoria: 'INF',
          valor: baseData.vvec_inf,
        },
      ];
      await api.post(
        'fare/multi-insert',
        { tarifas, id, dateTime: getFormattedDateTime() },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      // atualiza tabela fretamento
      const dataToUpdate = {
        taxa_embarque: baseData.taxa_embarque,
        markup: baseData.markup,
      };
      await api.patch(`charters/${id}`, dataToUpdate);

      carregaTarifas();
      setShowTarifas(true);
    } catch (error) {
      alert(`Erro: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  async function alteraTaxas(tipoTaxa) {
    try {
      const updateData = async (field, value) => {
        const dataToUpdate = { [field]: value };
        await api.patch(`charters/${id}`, dataToUpdate);
      };

      let n_taxaEmbarque = taxaEmbarque;
      let n_markup = markup;

      if (tipoTaxa === 'taxaEmbarque') {
        n_taxaEmbarque = normalizeCurrencyInput(
          document.getElementById('taxaEmbarque').value,
        );
        setTaxaEmbarque(n_taxaEmbarque);
        await updateData('taxa_embarque', n_taxaEmbarque);
      }

      if (tipoTaxa === 'markup') {
        n_markup = document.getElementById('markup').value.trim();
        setMarkup(n_markup);
        await updateData('markup', n_markup);
      }

      const hasChanges =
        (tipoTaxa === 'markup' && markupOld !== n_markup) ||
        (tipoTaxa === 'taxaEmbarque' && taxaEmbarqueOld !== n_taxaEmbarque);

      if (!hasChanges) return;

      setLoading(true);

      await processTarifas(n_taxaEmbarque, n_markup);

      carregaTarifas();
      setAlertState(true, 'alert-success', 'Gravado com sucesso!');
    } catch (error) {
      setAlertState(true, 'alert-danger', 'Erro na Gravação!');
      console.error('Erro ao alterar taxas:', error);
    } finally {
      setLoading(false);
      setOpenModal(false);
      setOpenModal2(false);
    }
  }

  function generateTarifas(valores, classe, taxaEmbarque, markup) {
    const categorias = ['ADT', 'CHD', 'INF'];
    return categorias.map((categoria, index) => ({
      classe,
      tipo: 'VV',
      categoria,
      valor: calculateSalesPrice(
        Number(valores[index]?.valor || 0),
        Number(markup),
        Number(taxaEmbarque),
      ),
    }));
  }

  async function processTarifas(taxaEmbarque, markup) {
    const token = localStorage.getItem('access_token');
    const tarifasData = [
      { classe: 'PC', valores: custo_primeiraClasse, totalFil: totalFilC1 },
      { classe: 'PR', valores: custo_premium, totalFil: totalFilC2 },
      { classe: 'EX', valores: custo_executiva, totalFil: totalFilC3 },
      { classe: 'EC', valores: custo_economica, totalFil: totalFilC4 },
    ];
    const formatDateTime = getFormattedDateTime();
    const tarifas = tarifasData.flatMap(({ valores, classe, totalFil }) =>
      totalFil > 0
        ? generateTarifas(valores, classe, taxaEmbarque, markup)
        : [],
    );

    try {
      await api.post(
        `fare/multi-insert`,
        { tarifas, id, dateTime: formatDateTime },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      console.error('Erro ao processar tarifas:', error);
    }
  }

  function setAlertState(isVisible, type, message) {
    setAlert(isVisible);
    setTypeAlert(type);
    setTxtAlert(message);
    setTimeout(() => setAlert(false), 4000);
  }

  function formatClasse(x) {
    if (x == 'Primeira Classe') {
      return nomenPC;
    }
    if (x == 'Premium') {
      return nomenPR;
    }
    if (x == 'Executiva') {
      return nomenEX;
    }
    if (x == 'Econômica') {
      return nomenEC;
    }
  }

  return (
    <>
      {loading && <LoadingAnimation />}

      <div className={alert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>

      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div
          className="globalModal text-center"
          style={{ padding: '70px 190px' }}
        >
          <GrClose
            className="closeModal"
            onClick={() => {
              setOpenModal(false);
              setMustBeConfirm(false);
            }}
          />
          <h5>
            <b>Alterar {changeTipo}</b>
          </h5>
          <h5>
            {formatClasse(changeClasse)} / {changeCateg}
          </h5>
          <form onSubmit={(e) => validaForm(e)} className="formPeq">
            <div className="row mb-3 mt-5">
              <div className="col-12">
                <IntlCurrencyInput
                  style={{
                    minHeight: '65px',
                    padding: '15px',
                    ...(mustBeConfirm
                      ? { background: '#F3F4F6', borderColor: '#F3F4F6' }
                      : {}),
                  }}
                  disabled={mustBeConfirm}
                  currency="BRL"
                  id="changeValor"
                  config={currencyConfig}
                  className="currency"
                  value={changeValor}
                  onChange={(e) =>
                    setChangeValor(
                      e.target.value !== '' ? e.target.value : 'R$ 0,00',
                    )
                  }
                />
              </div>
              {mustBeConfirm && (
                <div className="col-12">
                  <div
                    className="alert-not-floating warning"
                    style={{ marginTop: '10px', marginBottom: '36px' }}
                  >
                    <PiWarningFill color="#FA8E04" size={32} />
                    <span>
                      O valor informado é inferior ao Custo Operacional.
                    </span>
                  </div>
                </div>
              )}
              <div className={alertValor ? 'mb-3 mt-3' : 'hide'}>
                <p>Valor autorizado por:</p>
                <input type="text" required={alertValor ? true : false}></input>
              </div>
              {mustBeConfirm && (
                <div className="col-12 mt-2">
                  <button
                    style={{ minHeight: '65px', fontWeight: 600 }}
                    type="submit"
                  >
                    <span> Continuar</span>
                  </button>
                </div>
              )}
              <div className="col-12 mt-2">
                {mustBeConfirm && (
                  <button
                    style={{ minHeight: '65px', fontWeight: 600 }}
                    type="button"
                    className="outline"
                    onClick={() => {
                      setMustBeConfirm(false);
                    }}
                  >
                    <span> Alterar</span>
                  </button>
                )}
                {!mustBeConfirm && (
                  <button
                    style={{ minHeight: '65px', fontWeight: 600 }}
                    type="submit"
                  >
                    <span> Alterar</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={openModal2 ? 'bg_loading' : 'hide'}>
        <div className="globalModal p-4 text-center">
          <GrClose
            className="closeModal"
            onClick={() => setOpenModal2(false)}
          />

          <div className={showList == 'taxaembarque' ? '' : 'hide'}>
            <h5>Taxa de Embarque</h5>
            <div className="row mb-3 mt-5">
              <div className="col-12">
                <IntlCurrencyInput
                  currency="BRL"
                  id="taxaEmbarque"
                  config={currencyConfig}
                  className="currency"
                  value={taxaEmbarque}
                  onChange={(e) => setTaxaEmbarque(e.target.value)}
                />
              </div>
              <div className="col-12 mt-2">
                <button
                  type="submit"
                  onClick={() => alteraTaxas('taxaEmbarque')}
                >
                  <span>
                    <VscEdit /> Alterar
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className={showList == 'markup' ? '' : 'hide'}>
            <h5>Markup</h5>
            <div className="row mb-3 mt-5">
              <div className="col-12">
                <input
                  type="number"
                  step="0.01"
                  value={markup}
                  id="markup"
                  onChange={(e) => setMarkup(e.target.value)}
                />
              </div>
              <div className="col-12 mt-2">
                <button type="submit" onClick={() => alteraTaxas('markup')}>
                  <span>
                    <VscEdit /> Alterar
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className={showList == 'custo_primeiraClasse' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPC} / ADT</h5>
            <div className="listTarifa">
              {custo_primeiraClasse.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_primeiraClasse_chd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPC} / CHD</h5>
            <div className="listTarifa">
              {custo_primeiraclasse_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_primeiraClasse_inf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPC} / INF</h5>
            <div className="listTarifa">
              {custo_primeiraclasse_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_premium' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPR} / ADT</h5>
            <div className="listTarifa">
              {custo_premium.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_premium_chd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPR} / CHD</h5>
            <div className="listTarifa">
              {custo_premium_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_premium_inf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPR} / INF</h5>
            <div className="listTarifa">
              {custo_premium_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_executiva' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEX} / ADT</h5>
            <div className="listTarifa">
              {custo_executiva.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_executiva_chd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEX} / CHD</h5>
            <div className="listTarifa">
              {custo_executiva_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_executiva_inf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEX} / INF</h5>
            <div className="listTarifa">
              {custo_executiva_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_economica' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEC} / ADT</h5>
            <div className="listTarifa">
              {custo_economica.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_economica_chd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEC} / CHD</h5>
            <div className="listTarifa">
              {custo_economica_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'custo_economica_inf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEC} / INF</h5>
            <div className="listTarifa">
              {custo_economica_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={showList == 'primeiraClasse' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPC} / ADT</h5>
            <div className="listTarifa">
              {primeiraClasse.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvpcchd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPC} / CHD</h5>
            <div className="listTarifa">
              {primeiraclasse_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvpcinf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPC} / INF</h5>
            <div className="listTarifa">
              {primeiraclasse_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvpradt' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPR} / ADT</h5>
            <div className="listTarifa">
              {premium.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvprchd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPR} / CHD</h5>
            <div className="listTarifa">
              {premium_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvprinf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenPR} / INF</h5>
            <div className="listTarifa">
              {premium_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvexadt' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEX} / ADT</h5>
            <div className="listTarifa">
              {executiva.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvexchd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEX} / CHD</h5>
            <div className="listTarifa">
              {executiva_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvexinf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEX} / INF</h5>
            <div className="listTarifa">
              {executiva_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvecadt' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEC} / ADT</h5>
            <div className="listTarifa">
              {economica.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvecchd' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEC} / CHD</h5>
            <div className="listTarifa">
              {economica_chd.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={showList == 'vvecinf' ? '' : 'hide'}>
            <h5 className="mb-0">
              <b>Histórico</b>
            </h5>
            <h5>{nomenEC} / INF</h5>
            <div className="listTarifa">
              {economica_inf.map((data, index) => (
                <div className="lineTarifa lineTarifaIten" key={index}>
                  <div
                    className="row"
                    data-title={
                      'por: ' +
                      data.userInstance.firstName +
                      ' ' +
                      data.userInstance.familyName
                    }
                  >
                    <div className="itemdate ">
                      <span className="timeline">⚬</span>{' '}
                      {formatDateTime(data.date)}
                    </div>
                    <div className="itemprice ">
                      {formatCurrency(data.valor)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Saídas',
            link: PagePath.AereoSaidas,
          },
          {
            label: 'Precificação',
            link: PagePath.AereoSaidasPrecificar,
          },
        ]}
        title="Precificação"
      />

      <div className="flex flex-col">
        <RouteCardDetailed
          baggage={dataBase?.bagagem}
          airports={dataBaseAeroportos}
          departureOperator={dataBase?.outboundRoute?.fleet?.airline?.nome}
          departureFlight={dataBase?.outboundRoute?.voo}
          departureCompanyLogo={dataBase?.outboundRoute?.fleet?.airline?.logo}
          departureOrigin={dataBase?.outboundRoute?.origem}
          departureOriginTime={dataBase?.outboundRoute?.saida}
          departureOriginDate={dataBase?.data_frete}
          departureDestinationDate={dataBase?.data_frete}
          departureDestination={dataBase?.outboundRoute?.destino}
          departureDestinationTime={dataBase?.outboundRoute?.chegada}
          returnOriginDate={dataBase?.date_volta}
          returnDestinationDate={dataBase?.data_frete}
          returnOperator={dataBase?.returnRoute?.fleet?.airline?.nome}
          returnFlight={dataBase?.returnRoute?.voo}
          returnCompanyLogo={dataBase?.returnRoute?.fleet?.airline?.logo}
          returnOrigin={dataBase?.returnRoute?.origem}
          returnOriginTime={dataBase?.returnRoute?.saida}
          returnDestination={dataBase?.returnRoute?.destino}
          returnDestinationTime={dataBase?.returnRoute?.chegada}
          collapsible
          {...dataBase}
        />

        <div className="row m-0 mt-4 w-full rounded-md bg-white p-5">
          <div className="col">
            <label>Base tarifária</label>
            <select
              name="select"
              value={baseRegrasTarifarias}
              onChange={(e) => setBaseRegrasTarifarias(e.target.value)}
            >
              <option value="">Para prosseguir, escolha uma opção</option>
              {dataBaseRegrasTarifarias.map((data, index) => (
                <option key={index} value={data.id}>
                  {data.id} - {data.nome}{' '}
                </option>
              ))}
            </select>
          </div>
          <div className="col-2 flex items-end">
            <Button
              className="m-0 w-full"
              disabled={baseRegrasTarifarias.length == 0}
              onClick={carregaBaseTarifaria}
              label="Carregar"
            />
          </div>
          <div className={showTarifas ? 'col-12' : 'hide'}>
            <hr className="mb-0" />
          </div>
        </div>
      </div>

      <div
        className={`${showTarifas ? 'pt-5' : 'hide'} row m-0 mt-4 w-full rounded-md bg-white p-5`}
      >
        <div className="row mb-5">
          <div className="col-6">
            <label className="mt-0">Taxa de Embarque</label>
            {formatCurrency(taxaEmbarque)}{' '}
            <BiSolidPencil
              title="Alterar Valor"
              className="btnEdit"
              onClick={() => showListValor('taxaembarque')}
            />
          </div>
          <div className="col-6">
            <label className="mt-0">Markup</label>
            {markup}%{' '}
            <BiSolidPencil
              title="Alterar Valor"
              className="btnEdit"
              onClick={() => showListValor('markup')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6 ">
            <div className="col-12 mb-3">
              <h4 className="mb-0">Custo Operacional</h4>
              <small>Preço em reais por pessoa.</small>
            </div>

            <div className="contentEpecial">
              <div className="row borderBottom">
                <div className="col-3 mob2 mb-3">
                  <b>CLASSE</b>
                  <br />
                  <small>&nbsp;</small>
                </div>
                <div className="col-3 mob2 mb-3 text-center">
                  <b>ADT</b>
                  <br />
                  <small>(12+)</small>
                </div>
                <div className="col-3 mob2 mb-3 text-center">
                  <b>CHD</b>
                  <br />
                  <small>(2 a 11 anos)</small>
                </div>
                <div className="col-3 mob2 mb-3 text-center">
                  <b>INF</b>
                  <br />
                  <small>(0 a 23 meses)</small>
                </div>
              </div>

              <div
                className={totalFilC1 > 0 ? 'row border-bottom mt-3' : 'hide'}
              >
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenPC}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Custo Operacional',
                        'ADT',
                        custo_primeiraClasse.length > 0
                          ? custo_primeiraClasse[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_primeiraClasse.length > 0
                        ? custo_primeiraClasse[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Custo Operacional',
                        'ADT',
                        custo_primeiraClasse.length > 0
                          ? custo_primeiraClasse[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_primeiraClasse')}
                    className={custo_primeiraClasse.length == 0 ? 'hide' : ''}
                  />
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Custo Operacional',
                        'CHD',
                        custo_primeiraclasse_chd.length > 0
                          ? custo_primeiraclasse_chd[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_primeiraclasse_chd.length > 0
                        ? custo_primeiraclasse_chd[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Custo Operacional',
                        'CHD',
                        custo_primeiraclasse_chd.length > 0
                          ? custo_primeiraclasse_chd[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_primeiraClasse_chd')}
                    className={
                      custo_primeiraclasse_chd.length == 0 ? 'hide' : ''
                    }
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Custo Operacional',
                        'INF',
                        custo_primeiraclasse_inf.length > 0
                          ? custo_primeiraclasse_inf[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_primeiraclasse_inf.length > 0
                        ? custo_primeiraclasse_inf[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Custo Operacional',
                        'INF',
                        custo_primeiraclasse_inf.length > 0
                          ? custo_primeiraclasse_inf[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_primeiraClasse_inf')}
                    className={
                      custo_primeiraclasse_inf.length == 0 ? 'hide' : ''
                    }
                  />
                </div>
              </div>

              <div
                className={
                  totalFilC2 > 0 ? 'row border-bottom mt-3 pb-2' : 'hide'
                }
              >
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenPR}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Custo Operacional',
                        'ADT',
                        custo_premium.length > 0 ? custo_premium[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_premium.length > 0 ? custo_premium[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Custo Operacional',
                        'ADT',
                        custo_premium.length > 0 ? custo_premium[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_premium')}
                    className={custo_premium.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Custo Operacional',
                        'CHD',
                        custo_premium_chd.length > 0
                          ? custo_premium_chd[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_premium_chd.length > 0
                        ? custo_premium_chd[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Custo Operacional',
                        'CHD',
                        custo_premium_chd.length > 0
                          ? custo_premium_chd[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_premium_chd')}
                    className={custo_premium_chd.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Custo Operacional',
                        'INF',
                        custo_premium_inf.length > 0
                          ? custo_premium_inf[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_premium_inf.length > 0
                        ? custo_premium_inf[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Custo Operacional',
                        'INF',
                        custo_premium_inf.length > 0
                          ? custo_premium_inf[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_premium_inf')}
                    className={custo_premium_inf.length == 0 ? 'hide' : ''}
                  />
                </div>
              </div>

              <div
                className={
                  totalFilC3 > 0 ? 'row border-bottom mt-3 pb-2' : 'hide'
                }
              >
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenEX}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Custo Operacional',
                        'ADT',
                        custo_executiva.length > 0
                          ? custo_executiva[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_executiva.length > 0 ? custo_executiva[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Custo Operacional',
                        'ADT',
                        custo_executiva.length > 0
                          ? custo_executiva[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_executiva')}
                    className={custo_executiva.length == 0 ? 'hide' : ''}
                  />
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Custo Operacional',
                        'CHD',
                        custo_executiva_chd.length > 0
                          ? custo_executiva_chd[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_executiva_chd.length > 0
                        ? custo_executiva_chd[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Custo Operacional',
                        'CHD',
                        custo_executiva_chd.length > 0
                          ? custo_executiva_chd[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_executiva_chd')}
                    className={custo_executiva_chd.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Custo Operacional',
                        'INF',
                        custo_executiva_inf.length > 0
                          ? custo_executiva_inf[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_executiva_inf.length > 0
                        ? custo_executiva_inf[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Custo Operacional',
                        'INF',
                        custo_executiva_inf.length > 0
                          ? custo_executiva_inf[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_executiva_inf')}
                    className={custo_executiva_inf.length == 0 ? 'hide' : ''}
                  />
                </div>
              </div>

              <div className={totalFilC4 > 0 ? 'row mt-3' : 'hide'}>
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenEC}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Custo Operacional',
                        'ADT',
                        custo_economica.length > 0
                          ? custo_economica[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_economica.length > 0 ? custo_economica[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Custo Operacional',
                        'ADT',
                        custo_economica.length > 0
                          ? custo_economica[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_economica')}
                    className={custo_economica.length == 0 ? 'hide' : ''}
                  />
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Custo Operacional',
                        'CHD',
                        custo_economica_chd.length > 0
                          ? custo_economica_chd[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_economica_chd.length > 0
                        ? custo_economica_chd[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Custo Operacional',
                        'CHD',
                        custo_economica_chd.length > 0
                          ? custo_economica_chd[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_economica_chd')}
                    className={custo_economica_chd.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">Custo INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Custo Operacional',
                        'INF',
                        custo_economica_inf.length > 0
                          ? custo_economica_inf[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      custo_economica_inf.length > 0
                        ? custo_economica_inf[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Custo Operacional',
                        'INF',
                        custo_economica_inf.length > 0
                          ? custo_economica_inf[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('custo_economica_inf')}
                    className={custo_economica_inf.length == 0 ? 'hide' : ''}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="col-12 mb-3">
              <h4 className="mb-0">Valor de Venda</h4>
              <small>Preço em reais por pessoa.</small>
            </div>

            <div className="contentEpecial">
              <div className="row borderBottom">
                <div className="col-3 mob2 mb-3">
                  <b>CLASSE</b>
                  <br />
                  <small>&nbsp;</small>
                </div>
                <div className="col-3 mob2 mb-3 text-center">
                  <b>ADT</b>
                  <br />
                  <small>(12+)</small>
                </div>
                <div className="col-3 mob2 mb-3 text-center">
                  <b>CHD</b>
                  <br />
                  <small>(2 a 11 anos)</small>
                </div>
                <div className="col-3 mob2 mb-3 text-center">
                  <b>INF</b>
                  <br />
                  <small>(0 a 23 meses)</small>
                </div>
              </div>

              <div
                className={totalFilC1 > 0 ? 'row border-bottom mt-3' : 'hide'}
              >
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenPC}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Valor de Venda',
                        'ADT',
                        primeiraClasse.length > 0 ? primeiraClasse[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      primeiraClasse.length > 0 ? primeiraClasse[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Valor de Venda',
                        'ADT',
                        primeiraClasse.length > 0 ? primeiraClasse[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('primeiraClasse')}
                    className={primeiraClasse.length == 0 ? 'hide' : ''}
                  />
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Valor de Venda',
                        'CHD',
                        primeiraclasse_chd.length > 0
                          ? primeiraclasse_chd[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      primeiraclasse_chd.length > 0
                        ? primeiraclasse_chd[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Valor de Venda',
                        'CHD',
                        primeiraclasse_chd.length > 0
                          ? primeiraclasse_chd[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvpcchd')}
                    className={primeiraclasse_chd.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Valor de Venda',
                        'INF',
                        primeiraclasse_inf.length > 0
                          ? primeiraclasse_inf[0].valor
                          : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      primeiraclasse_inf.length > 0
                        ? primeiraclasse_inf[0].valor
                        : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Primeira Classe',
                        'Valor de Venda',
                        'INF',
                        primeiraclasse_inf.length > 0
                          ? primeiraclasse_inf[0].valor
                          : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvpcinf')}
                    className={primeiraclasse_inf.length == 0 ? 'hide' : ''}
                  />
                </div>
              </div>

              <div
                className={
                  totalFilC2 > 0 ? 'row border-bottom mt-3 pb-2' : 'hide'
                }
              >
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenPR}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Valor de Venda',
                        'ADT',
                        premium.length > 0 ? premium[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(premium.length > 0 ? premium[0].valor : 0)}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Valor de Venda',
                        'ADT',
                        premium.length > 0 ? premium[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvpradt')}
                    className={premium.length == 0 ? 'hide' : ''}
                  />
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Valor de Venda',
                        'CHD',
                        premium_chd.length > 0 ? premium_chd[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      premium_chd.length > 0 ? premium_chd[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Valor de Venda',
                        'CHD',
                        premium_chd.length > 0 ? premium_chd[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvprchd')}
                    className={premium_chd.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Valor de Venda',
                        'INF',
                        premium_inf.length > 0 ? premium_inf[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      premium_inf.length > 0 ? premium_inf[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Premium',
                        'Valor de Venda',
                        'INF',
                        premium_inf.length > 0 ? premium_inf[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvprinf')}
                    className={premium_inf.length == 0 ? 'hide' : ''}
                  />
                </div>
              </div>

              <div
                className={
                  totalFilC3 > 0 ? 'row border-bottom mt-3 pb-2' : 'hide'
                }
              >
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenEX}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Valor de Venda',
                        'ADT',
                        executiva.length > 0 ? executiva[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      executiva.length > 0 ? executiva[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Valor de Venda',
                        'ADT',
                        executiva.length > 0 ? executiva[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvexadt')}
                    className={executiva.length == 0 ? 'hide' : ''}
                  />
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Valor de Venda',
                        'CHD',
                        executiva_chd.length > 0 ? executiva_chd[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      executiva_chd.length > 0 ? executiva_chd[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Valor de Venda',
                        'CHD',
                        executiva_chd.length > 0 ? executiva_chd[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvexchd')}
                    className={executiva_chd.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Valor de Venda',
                        'INF',
                        executiva_inf.length > 0 ? executiva_inf[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      executiva_inf.length > 0 ? executiva_inf[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Executiva',
                        'Valor de Venda',
                        'INF',
                        executiva_inf.length > 0 ? executiva_inf[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvexinf')}
                    className={executiva_inf.length == 0 ? 'hide' : ''}
                  />
                </div>
              </div>

              <div className={totalFilC4 > 0 ? 'row mt-3' : 'hide'}>
                <div className="col-3 classAlignMiddle">
                  <div className="classAlignMiddle">{nomenEC}</div>
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">ADT</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Valor de Venda',
                        'ADT',
                        economica.length > 0 ? economica[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      economica.length > 0 ? economica[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Valor de Venda',
                        'ADT',
                        economica.length > 0 ? economica[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvecadt')}
                    className={economica.length == 0 ? 'hide' : ''}
                  />
                </div>
                <div className="col-3 colTarifa">
                  <label className="desk mt-1">CHD</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Valor de Venda',
                        'CHD',
                        economica_chd.length > 0 ? economica_chd[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      economica_chd.length > 0 ? economica_chd[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Valor de Venda',
                        'CHD',
                        economica_chd.length > 0 ? economica_chd[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvecchd')}
                    className={economica_chd.length == 0 ? 'hide' : ''}
                  />
                </div>

                <div className="col-3 colTarifa">
                  <label className="desk mt-1">INF</label>
                  <h6
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Valor de Venda',
                        'INF',
                        economica_inf.length > 0 ? economica_inf[0].valor : 0,
                      )
                    }
                    className="lkAddValor"
                  >
                    {formatCurrency(
                      economica_inf.length > 0 ? economica_inf[0].valor : 0,
                    )}
                  </h6>
                  <BiSolidPencil
                    title="Alterar Valor"
                    onClick={() =>
                      alteraValor(
                        'Econômica',
                        'Valor de Venda',
                        'INF',
                        economica_inf.length > 0 ? economica_inf[0].valor : 0,
                      )
                    }
                  />
                  <TbClipboardList
                    title="Histórico de Tarifas"
                    onClick={() => showListValor('vvecinf')}
                    className={economica_inf.length == 0 ? 'hide' : ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 flex w-full justify-end">
        <Link to={PagePath.AereoSaidas} relative="path">
          <Button label="Voltar" variant="ghost" className="w-[200px]" />
        </Link>
      </div>
    </>
  );
}
