import Globals from '../components/Globals';
import { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { PagePath } from '../pages-config';
import api from '@services/api';
import PageHeader from '@components/page-header';

function MeusDados() {
  const navigate = useNavigate();
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert, setTxtAlert] = useState('');
  const [firstName, setFirstName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let query =
      "SELECT * FROM `vpcharter_users` WHERE `id` = '" + Globals.userId + "'  ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(Globals.endPoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setFirstName(response.data[0].firstName);
          setFamilyName(response.data[0].familyName);
          setEmail(response.data[0].email);
          setPass(response.data[0].pass);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Usuário não encontrado!');
          setTimeout(() => setTypeAlert(''), 3000);
        }
      })
      .catch((error) => alert(error));
  }, []);

  function closeScrean() {
    setTypeAlert('');
    navigate(PagePath.Home);
  }

  async function validaForm(e) {
    setLoading(true);
    e.preventDefault();

    try {
      await api.patch(`/users/${Globals.userId}`, {
        email,
        first_name: firstName,
        family_name: familyName,
        password: pass,
      });

      Globals.userName = firstName;
      Globals.userEmail = email;
      setTypeAlert('alert-success');
      setTxtAlert('Dados Alterados com sucesso');
      setTimeout(() => closeScrean(), 5000);
    } catch {
      setTypeAlert('alert-danger');
      settitleAlert('Erro na Gravação!');
      setTimeout(() => setTypeAlert(''), 3000);
    }

    setLoading(false);
  }

  return (
    <>
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Meus dados', link: PagePath.MeusDados },
        ]}
        title="Meus Dados"
      />

      <div className="contentAll">
        <div className="row">
          <div className="col-12">
            <form onSubmit={(e) => validaForm(e)}>
              <div className="row">
                <div className="col-6">
                  <label>Primeiro Nome</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-6">
                  <label>Sobrenome</label>
                  <input
                    type="text"
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-6">
                  <label>E-mail</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="col-6">
                  <label>Senha</label>
                  <input
                    type="password"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6"></div>
                <div className="col">
                  <button type="submit">
                    <span className={loading ? 'hide' : ''}>
                      <FiEdit /> Salvar
                    </span>
                    <span className={loading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeusDados;
