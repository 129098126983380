import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectV2 } from '@components/new-components/select-v2';
import {
  SELECT_OPTIONS_ADMIN,
  SELECT_OPTIONS_BUYER_ADMIN,
} from '@data/roles.data';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import Globals from '../components/Globals';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

export default function AdminUsuarios() {
  const [endpoint] = useState(Globals.endPoint);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [listAgenciaCliente, seListAgenciaCliente] = useState([]);

  const { session } = useAuthentication();

  useEffect(() => {
    listaUsers();
    listaAgenciaCliente();
  }, []);

  function listaAgenciaCliente() {
    let query =
      'SELECT * FROM `vpcharter_contratante` ORDER BY `vpcharter_contratante`.`razaosocial` ASC ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          seListAgenciaCliente(response.data);
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }

  function listaUsers() {
    setLoading(true);
    let query =
      session.role === Role.CompradorAdministrador
        ? 'SELECT * FROM `vpcharter_users` WHERE agenciaCliente = ' +
          session.agency.id
        : 'SELECT * FROM `vpcharter_users` ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(Globals.endPoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setUsers(response.data);
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }

  function changeStatus(e, idUser) {
    setLoading(true);
    let query =
      "UPDATE `vpcharter_users` SET  `status` = '" +
      e +
      "' WHERE `vpcharter_users`.`id` = '" +
      idUser +
      "'  ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then(() => {
        listaUsers();
      })
      .catch((error) => alert(error));
  }

  function changeUserActive(e, idUser) {
    setLoading(true);
    let newUserActive = '0';
    if (e == '0') {
      newUserActive = '1';
    }
    let query =
      "UPDATE `vpcharter_users` SET  `userActive` = '" +
      newUserActive +
      "' WHERE `vpcharter_users`.`id` = '" +
      idUser +
      "'  ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then(() => {
        listaUsers();
      })
      .catch((error) => alert(error));
  }

  function changeAgenciaCliente(e, idUser) {
    setLoading(true);
    let query =
      "UPDATE `vpcharter_users` SET  `agenciaCliente` = '" +
      e +
      "' WHERE `vpcharter_users`.`id` = '" +
      idUser +
      "'  ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then(() => {
        listaUsers();
      })
      .catch((error) => alert(error));
  }

  function createNewUser() {
    navigate(PagePath.AdminUsuariosNovo);
  }

  const SELECT_OPTIONS_COMPANIES = listAgenciaCliente
    ?.filter((data) => {
      return (
        session.role === Role.Administrador ||
        (session.role === Role.CompradorAdministrador &&
          data.nome_fantasia === session.agency.nomeFantasia)
      );
    })
    .map((data) => ({
      value: data.id,
      label: data.nome_fantasia,
    }));

  return (
    <>
      {loading && <LoadingAnimation />}

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          { label: 'Sistemas - Usuários', link: PagePath.AdminUsuarios },
        ]}
        title="Usuários"
        buttonClick={() => createNewUser()}
        button="+ Novo"
      />

      <div className="flex flex-col">
        <div className="btnTable">
          {/*
              <select onChange={(e) => changeLimit(e.target.value)} className={dataTotal > 25 ? 'selectLimit' : 'hidden'}>
                  <option value="25">25</option>
                  {dataTotal > 50 ? <option value="50">50</option> : undefined}
                  {dataTotal > 100 ? <option value="100">100</option> : undefined}
                  {dataTotal > 250 ? <option value="250">250</option> : undefined}
                  {dataTotal > 500 ? <option value="500">500</option> : undefined}
              </select>
              <div className="tdtoal">{startQuery+1} - {newStartQuery}  de  {formatNumber(dataTotal)}</div>
              <button type="button" className="btn btn-light m-1" onClick={() => lessData()} disabled={startQuery==0 ? true : false}><BsChevronLeft /></button>
              <button type="button" className="btn btn-light m-1" onClick={() => moreData()} disabled={newStartQuery==dataTotal ? true : false}><BsChevronRight /></button>
              <button onClick={() => onTableChange()} type="button" className="btn btn-light m-1 nomob">{tdShort ? <RiExpandLeftRightFill title="Expandir" /> : <RiContractLeftRightFill title="Contrair" />}</button>*/}
        </div>
      </div>

      <div className="m-0 w-full rounded-md bg-white p-4">
        <div className="w-full">
          <div className="col-12 table_list">
            <table className="tblDefault">
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>E-mail</td>
                  <td>Status</td>
                  <td>Empresa</td>
                  <td className="text-center">Ativo</td>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td>
                      {user.firstName} {user.familyName}
                    </td>
                    <td>{user.email}</td>
                    <td className="text-center">
                      <SelectV2
                        options={
                          session.role === Role.Administrador
                            ? SELECT_OPTIONS_ADMIN
                            : SELECT_OPTIONS_BUYER_ADMIN
                        }
                        value={user.status}
                        onChange={(value) => changeStatus(value, user.id)}
                      />
                    </td>
                    <td>
                      <SelectV2
                        options={SELECT_OPTIONS_COMPANIES}
                        value={user.agenciaCliente}
                        onChange={(value) =>
                          changeAgenciaCliente(value, user.id)
                        }
                      />

                      {/*
                          <select value={user.status} onChange={(e)=>changeStatus(e.target.value, user.id)}  required >
                            <option value="0">Viagens Promo</option>
                          </select>
                            */}
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        name="scales"
                        value={user.userActive}
                        checked={user.userActive == '0' ? true : false}
                        onChange={(e) =>
                          changeUserActive(e.target.value, user.id)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
