import { forwardRef, useId } from 'react';

export const InputRadio = forwardRef(function InputRadio(
  { label, className, ...props },
  ref,
) {
  const id = useId();

  return (
    <div className="flex items-center space-x-2.5">
      <input
        {...props}
        id={id}
        className={`size-5 accent-secondary ${className}`}
        ref={ref}
        type="radio"
      />
      {label && (
        <label
          className={`m-0 text-body text-neutral-800 ${props.checked ? 'font-semibold' : 'font-normal'}`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
});
