import { tv } from 'tailwind-variants';

export const styles = {
  userMenuInfoContainer: tv({
    base: `
      flex
      flex-col
      bg-secondary-100
      p-5
      gap-1
    `,
  }),
  userInfoName: tv({
    base: `
      text-[#E41047]
      font-bold
      text-sm
    `,
  }),
  userInfoEmail: tv({
    base: `
      text-neutral-800
      text-sm
    `,
  }),
  userInfoAgency: tv({
    base: `
      text-neutral-800
      font-bold
      text-sm
    `,
  }),
  menuUserDataContent: tv({
    base: `
      bg-white
      flex
      flex-col
      gap-1
      text-neutral-800
      text-sm
      [list-style-type:none]
      flex
      flex-col
      gap-5
      p-5
      m-0
    `,
  }),
  menuUserDataLink: tv({
    base: `
      text-neutral-800
      text-sm
      font-normal
      [text-align:left]
    `,
  }),
  menuUserDataLogout: tv({
    base: `
      cursor-pointer
    `,
  }),
  userMenuContainer: tv({
    base: `
      ml-auto
      relative
      hidden
      lg:block
    `,
  }),
  menuDesktopBadge: tv({
    base: `
     cursor-pointer
     flex
     items-center
     gap-1
     border
     border-solid
     border-[#E41047]
     rounded-3xl
     text-[#E41047]
     text-sm
     font-semibold
     px-3
     py-2
     lg:block
     lg:flex
     [user-select:none]
    `,
  }),
  userMenuDropdown: tv({
    base: `
      hidden
      shadow-header
      lg:block
      lg:absolute
      lg:right-0
      lg:w-[390px]
      lg:mt-1
    `,
  }),
};
