import { Button } from '@components/button';
import { Modal } from '@components/modal';
import { toaster } from '@system/utils/toaster';
import { useState } from 'react';
import { IoIosWarning } from 'react-icons/io';

const modalContent = {
  notCancelable: {
    title: 'Ops, essa reserva não pode ser cancelada',
    message:
      'A reserva só pode ser cancelada com o prazo de no mínimo 48h <br> para a data de partida.',
    closeButtonLabel: 'Fechar',
  },
  cancelableWithoutFeeAndRefund: {
    title: 'Essa reserva poderá ser cancelada sem alteração de valores',
    message: 'Deseja continuar com o cancelamento?',
    buttonLabel: 'Cancelar reserva',
  },
  cancelableWithFee: (valueFee) => ({
    title: `Essa reserva poderá ser cancelada com um custo adicional de ${valueFee} `,
    message: 'Deseja continuar com o cancelamento?',
    buttonLabel: 'Cancelar reserva',
  }),
  cancelableWithRefund: (valueFee, valueRefund) => ({
    title: `Essa reserva poderá ser cancelada com direito a reembolso de ${valueRefund}`,
    message: 'Deseja continuar com o cancelamento?',
    buttonLabel: 'Cancelar reserva',
  }),
  cancelableWithFeeAndRefund: (valueFee, valueRefund) => ({
    title: `Essa reserva poderá ser cancelada com um custo adicional de ${valueFee} e um reembolso de ${valueRefund}`,
    message: 'Deseja continuar com o cancelamento?',
    buttonLabel: 'Cancelar reserva',
  }),
  default: {
    title: 'Erro',
    message: 'Status de reserva desconhecido.',
    closeButtonLabel: 'Fechar',
  },
};

export function ModalCancelReservation({
  open,
  handleCancel,
  handleClose,
  status,
  valueFee,
  valueRefund,
}) {
  const modalData =
    typeof modalContent[status] === 'function'
      ? modalContent[status](valueFee, valueRefund)
      : modalContent[status] || modalContent.default;

  const {
    title,
    message,
    buttonLabel,
    closeButtonLabel = 'Fechar',
  } = modalData;

  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleCancelReservation = () => {
    if (!termsAccepted && status !== 'notCancelable') {
      toaster('error', 'Você deve aceitar os termos para cancelar a reserva.', {
        position: 'top-center',
      });
      return;
    }
    handleCancel();
  };

  const textColorClass =
    status === 'notCancelable' ? 'text-warning-500' : 'neutral-800';

  return (
    <Modal
      customPadding="py-[60px] px-[60px]"
      customGap="gap-4"
      open={open}
      handleClose={handleClose}
    >
      <div className="flex flex-col items-center gap-4">
        {status === 'notCancelable' && (
          <IoIosWarning className={textColorClass} size={38} />
        )}
        <h1
          className={`mb-0 ${textColorClass} text-center`}
          style={{ fontSize: '24px', lineHeight: '30px', fontWeight: 600 }}
        >
          {title}
        </h1>
      </div>
      <p
        className="m-0 w-1/2 text-center text-neutral-800"
        style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>

      {status !== 'notCancelable' && (
        <div className="flex w-full flex-col items-center">
          <label className="mb-0 mt-0 flex cursor-pointer items-center text-sm text-neutral-800 hover:text-secondary-900">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              className="mr-2 h-[20px] w-[20px]"
            />
            Li e concordo com as regras
          </label>
        </div>
      )}

      <div className="mt-7 flex items-center gap-4">
        <Button
          label={closeButtonLabel}
          variant="dangerOutline"
          onClick={handleClose}
        />
        {buttonLabel && (
          <Button
            label={buttonLabel}
            variant="danger"
            onClick={handleCancelReservation}
          />
        )}
      </div>
    </Modal>
  );
}
