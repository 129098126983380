import { GiHamburgerMenu } from 'react-icons/gi';
import { RiCloseLargeFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import { SearchReservationTopNavBar } from '../../components/search-reservation-topnavbar';
import { PagePath } from '../../pages-config';
import { styles } from './styles';

export function HeaderV2({ isMenuOpen, handleMenuClick }) {
  return (
    <header className={styles.header()}>
      <div className={styles.headerContent()}>
        <Link to={PagePath.Home}>
          <div className={styles.headerLogoLink()}>
            <Logo />
          </div>
        </Link>
        <div className={styles.input()}>
          <SearchReservationTopNavBar />
        </div>
        <div
          className={styles.burderMenu()}
          role="button"
          onClick={handleMenuClick}
        >
          {!isMenuOpen && <GiHamburgerMenu />}
          {isMenuOpen && <RiCloseLargeFill />}
        </div>
      </div>
    </header>
  );
}
