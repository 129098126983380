import { tv } from 'tailwind-variants';

export const styles = {
  header: tv({
    base: `
      w-full 
      bg-white 
      display 
      flex 
      justify-between 
      border-b 
      border-neutral-300
      h-[40px]
      px-[10px]
      py-[10px] 
      lg:h-[60px] 
      lg:px-[20px] 
      lg:py-[10px]
    `,
  }),
  headerContent: tv({
    base: 'flex w-full justify-between items-center',
  }),
  headerLogoLink: tv({
    base: 'h-[16px] lg:h-[26px] flex items-center',
  }),
  input: tv({
    base: 'w-[300px] hidden lg:flex items-center justify-center',
  }),
  burderMenu: tv({
    base: 'lg:hidden px-2 flex items-center justify-center text-[#E41047] text-md',
  }),
};
